import React from "react";
import { useState, useEffect } from "react";

function ShoppingRow({ list }) {
	const [shoppingList, setShoppingList] = useState([]);
	useEffect(() => {
		const updatedList = list.map(item => {
			const producto = item.formatosVenta[0];
			item.haveAll = false;
			item.haveAmount = 0;
			producto.purchase = producto.fraccionable ? item.peso : producto.amountToPurchase;
			return item;
		});
		setShoppingList(updatedList);
	}, [list]);

	return (
		<>
			<h4>Lista de compras</h4>
			<div className="align-right">
				<button
					className="btn btn-primary"
					onClick={() => {
						const textList = shoppingList
							.map(item => {
								const producto = item.formatosVenta[0];
								if (producto.fraccionable && item.haveAll || producto.purchase === 0) return "";
								if (producto.fraccionable)
									return `${producto.purchase}g ${item.nombreIngr} ${producto.marca}\n`;
								return `${producto.purchase}x ${producto.descripcion} ${producto.marca}\n`;
							})
							.join("");
						navigator.clipboard?.writeText(textList);
					}}
				>
					Copiar lista
				</button>
			</div>

			<table>
				<thead>
					<tr>
						<th>Cantidad</th>
						<th>Ingrediente</th>
						<th>Presentación</th>
						<th>Marca</th>
						<th>Departamento</th>
						<th>Disponibles</th>
						<th>Comprar</th>
						<th>Diferencia</th>
					</tr>
				</thead>

				<tbody>
					{shoppingList.map((item, index) => {
						const producto = item.formatosVenta[0];
						const remaining = producto.fraccionable
							? item.haveAll
								? 0
								: producto.purchase - item.peso
							: producto.presentacion * (item.haveAmount + producto.purchase) - item.peso;

						return (
							<tr key={index}>
								<td>{item.peso}g</td>
								<td>{item.nombreIngr}</td>
								<td>{producto.fraccionable ? "Fraccionable" : producto.descripcion}</td>
								<td>{producto.marca}</td>
								<td>{producto.localVenta.nombre}</td>

								<td>
									{producto.fraccionable ? (
										<span className="side-by-side">
											<input
												className="visible"
												type="checkbox"
												checked={item.haveAll}
												onChange={event => {
													item.haveAll = event.target.checked;
													producto.purchase = item.haveAll ? 0 : item.peso;
													setShoppingList([...shoppingList]);
												}}
											/>
											<p className="checkbox-label">Tengo</p>
										</span>
									) : (
										<span className="side-by-side">
											<input
												className="listInput"
												type="number"
												min={0}
												value={item.haveAmount}
												onChange={event => {
													item.haveAmount = Number(event.target.value);
													producto.purchase = Math.max(producto.amountToPurchase - item.haveAmount, 0);
													setShoppingList([...shoppingList]);
												}}
											/>
										</span>
									)}
								</td>

								<td>
									<span className="side-by-side">
										<input
											className="listInput"
											type={producto.fraccionable ? "" : "number"}
											min={0}
											value={producto.purchase}
											onChange={event => {
												producto.purchase = Number(event.target.value);
												setShoppingList([...shoppingList]);
											}}
										/>
										{producto.fraccionable && <p>g</p>}
									</span>
								</td>

								<td>{remaining >= 0 ? `${remaining}g` : "Insuficiente"}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}

export default ShoppingRow;
