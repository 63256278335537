import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDataProvider } from "../providers/data";
import { STATUS_SERVER } from "../providers/utils/functions";

function RegisterPageRetail() {
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);

	const [signUpForm, setSignUpForm] = useState({
		serial: searchParams.get("serial"),
		client_id: "",
		name: "",
		surname: "",
		phone: "",
		email: "",
		address: ""
	});

	const dataProvider = useDataProvider();
	const [serverMsg, setServerMsg] = useState({ status: null, msg: "", show: false });

	const submitForm = async e => {
		e.preventDefault();
		setServerMsg({ status: null, msg: "", show: false });

		try {
			const { client_id, serial, name, surname, phone, email, address } = signUpForm;
			const response = await dataProvider.create("usuarios/register_retail", {
				data: {
					clientId: client_id.replace(/\D/g, ""),
					serialRobot: serial,
					nombre: name,
					apellido: surname,
					cellphone: phone,
					email,
					address
				}
			});
			if (response.status === 200) {
				const user = response.data.usuario;
				const pswd = response.data.pwd;
				const msg = `Usuario creado con éxito. Puedes iniciar sesion con el usuario: ${user} y contraseña: ${pswd}`;
				setServerMsg({ status: STATUS_SERVER.SUCCESS, msg, show: true });
				//setShowDialog(true);
			}
		} catch (error) {
			const msg = error.response.data.message;
			setServerMsg({ status: STATUS_SERVER.ERROR, msg, show: true });
			//setShowDialog(true);
		}
	};

	return (
		<form action="" className="transition form-register" onSubmit={e => submitForm(e)} autoComplete="off">
			{/*showDialog && (
			<Dialog
				buttons={
					<>
						<button className="btn btn-primary btn-enviar" onClick={() => serverMsg.status === STATUS_SERVER.ERROR ? setShowDialog(false):navigate('/')}>
							{t("confirm")}
						</button>
					</>
				}
			>
			
								<div
						className={`msj-dlg ${
							serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
						}`}
					>
						{serverMsg.msg}
					</div><div></div>
			</Dialog>
					)*/}
			
			<div className="register-container">
				<header className="text-center">
					<h6>Registra tu iChef</h6>
				</header>

				{serverMsg && serverMsg.show && (
					<div
						className={`msj ${
							serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
						}`}
					>
						{serverMsg.msg}
					</div>
				)}

				<div>
					<label htmlFor="serial">Número de serie del robot:</label>
					<input
						type="text"
						name="serial"
						placeholder="Número de serie"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ serial: e.target.value } })}
						value={signUpForm.serial}
						required
					/>

					<label htmlFor="name">Nombre:</label>
					<input
						type="text"
						name="name"
						placeholder="Nombre"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ name: e.target.value } })}
						value={signUpForm.name}
						required
					/>

					<label htmlFor="surname">Apellido:</label>
					<input
						type="text"
						name="surname"
						placeholder="Apellido"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ surname: e.target.value } })}
						value={signUpForm.surname}
						required
					/>

					<label htmlFor="client_id">Cédula de identidad:</label>
					<input
						type="text"
						name="client_id"
						placeholder="Cédula"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ client_id: e.target.value } })}
						value={signUpForm.client_id}
						required
					/>

					<label htmlFor="phone">Teléfono:</label>
					<input
						type="tel"
						name="phone"
						placeholder="Teléfono"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ phone: e.target.value } })}
						value={signUpForm.phone}
						required
					/>

					<label htmlFor="email">E-mail:</label>
					<input
						type="email"
						name="email"
						placeholder="E-mail"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ email: e.target.value } })}
						value={signUpForm.email}
						required
					/>

					<label htmlFor="address">Dirección:</label>
					<input
						type="text"
						name="address"
						placeholder="Dirección (opcional)"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ address: e.target.value } })}
						value={signUpForm.address}
					/>
				</div>
				<button className="transition" name="submit" type="submit">
					Registrar
				</button>
			</div>
		</form>
	);
}

export default RegisterPageRetail;
